.merciPage {
    h2 {
        padding: 0;
        font-size: 28px;
        font-weight: 800;
        line-height: 24px;
    }
    p {
        padding-bottom: 50px;
        font-weight: 500;
        font-size: 20px;
    }
    .remerciement {
        display: flex;
        align-items: center;
        gap: 0 30px;
        padding-bottom: 50px;
        margin-left: auto;
        margin-right: auto;
        .heart {
            width: 50px;
        }
        .detail {
            width: 900px;
            h5 {
                padding: 0;
                margin: 0;
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
            }
            p {
                padding: 0;
                margin: 5px 0 0;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}

$breakpoint-tablet: 1034px;

@media (max-width: $breakpoint-tablet) {
    .merciPage {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        .cardProjetTermine {
            cursor: pointer;
            max-width: 300px;
            min-width: 300px;
            img {
                max-width: 300px;
                min-width: 300px;
                height: 350px;
                object-fit: cover;
                border-radius: 15px;
            }
        }
    }
}
$breakpoint-mobile: 750px;
@media (max-width: $breakpoint-mobile) {
    .merciPage {
        h2 {
            padding: 0;
            font-size: 24px;
            font-weight: 800;
            line-height: 24px;
        }
        p {
            padding-bottom: 50px;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
        }
    }
}
