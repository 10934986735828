.acutalitePage {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px 10px;
}

.acutaliteDetail {
    position: relative;
    .header {
        height: 100px;
        text-align: center;
        height: fit-content;
    }
    .featuredImg {
        margin-left: auto;
        margin-right: auto;
        max-width: 700px;
        max-height: 700px;
        display: flex;
    }
    .challenge {
        position: relative;
        margin-top: 10px;
        top: 25px;
        text-align: center;
        width: fit-content;
        padding: 20px 50px;
        height: 39px;
        background: #ed4c67;
        border-radius: 28px;
        border: none;
        color: #ffffff;
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        text-transform: uppercase;
        text-decoration: none;
    }
    .body {
        .alignleft {
            float: left;
            margin-right: 25px;
        }
        .alignright {
            float: right;
            margin-right: 0;
            margin-left: 25px;
        }
        .aligncenter {
            display: flex;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
        .vp-center {
            max-width: 716.57px;
            margin: 0px auto 0px auto;
            display: block !important;
            border-radius: 4px;
        }
        iframe {
            max-width: 716.57px;
            min-width: 716.57px;
            display: flex;
            margin-left: auto;
            margin-right: auto;
            .player {
                max-width: 716.57px;
                min-width: 716.57px;
            }
        }

        figure {
            margin: 26px auto 30px auto;
            img {
                max-width: 716.57px;
                width: 100%;
                min-width: 100%;
                margin: 0px auto 0px auto;
                display: block !important;
                border-radius: 4px;
            }
            &.alignleft {
                float: left;
                margin-right: 25px;
            }
            &.alignright {
                float: right;
                margin-right: 0;
                margin-left: 25px;
            }
            &.aligncenter {
                margin: 0 auto;
                text-align: center;
            }
        }
    }
    .otherImg {
        img {
            position: relative;
            width: 300px;
            height: 100%;
        }
    }
    span {
        font-size: 16px;
        font-weight: 600;
        line-height: 25px;
        position: relative;
        float: right;
    }
}
$breakpoint-tablet: 1034px;

@media (max-width: $breakpoint-tablet) {
    .acutalitePage {
        margin-left: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .acutaliteDetail {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        .body {
            img {
                display: flex;
                margin: 0 auto;
            }
        }
        .otherImg {
            img {
                position: relative;
                width: 200px;
                height: 100%;
            }
        }
    }
}
$breakpoint-mobile: 750px;

@media (max-width: $breakpoint-mobile) {
    .acutalitePage {
        margin-left: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .acutaliteDetail {
        flex-wrap: wrap;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        .featuredImg {
            margin-left: auto;
            margin-right: auto;
            min-width: 340px;
            max-width: 400px;
            height: 250px;
            display: flex;
        }
        .body {
            img {
                width: 90%;
                height: auto;
                padding-bottom: 10px;
            }
            .alignleft {
                margin: 0 auto !important;
                float: none;
            }
            .alignright {
                float: none;
                margin: 0 auto !important;
            }
            .aligncenter {
                float: none;
                margin: 0 auto;
            }
            .vp-center {
                max-width: 716.57px;
                margin: 0px auto 0px auto;
                display: block !important;
                border-radius: 4px;
            }
            iframe {
                min-width: 340px;
                max-width: 400px;
                height: 250px;
                display: flex;
                margin-left: auto;
                margin-right: auto;
                .player {
                    min-width: 340px;
                    max-width: 400px;
                    height: 250px;
                }
            }

            figure {
                margin: 26px auto 30px auto;
                img {
                    min-width: 340px;
                    max-width: 400px;
                    height: 250px;
                    margin: 0px auto 0px auto;
                    display: block !important;
                    border-radius: 4px;
                }
                &.alignleft {
                    margin: 0 auto;
                }
                &.alignright {
                    margin: 0 auto;
                }
                &.aligncenter {
                    margin: 0 auto;
                }
            }
        }
        .otherImg {
            flex-wrap: wrap;
            img {
                margin-left: auto;
                margin-right: auto;
                position: relative;
                min-width: 340px;
                max-width: 340px;
                height: 100%;
            }
        }
    }
}
