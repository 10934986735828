.footer {
    height: 285px;
    max-height: 280px;
    width: 100%;
    background-color: #41a592;
    position: relative;
    margin-top: 100px;
    padding: 60px 0 10px;
    img {
        position: absolute;
        top: -0px;
        width: 100%;
        flex-shrink: 0;
        min-width: 100%;
    }
    .footerSection {
        width: 900px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        p {
            margin-top: 60px;
            width: 800px;
            text-align: center;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 59px;
            letter-spacing: 0.6px;
            text-transform: capitalize;
            color: #ffffff;
        }
    }
    .footerDiv {
        width: 900px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        p {
            width: 300px;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 59px;
            letter-spacing: 0.6px;
            text-transform: capitalize;
            color: #ffffff;
        }
        nav {
            padding-top: 0;
            display: flex;
            flex-direction: column;
            a,
            li {
                list-style: none;
                margin: 5px;
                text-decoration: none;
                color: #ffffff;
                text-transform: uppercase;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0.6px;
                &:hover {
                    color: #ed4c67;
                }
                button {
                    margin-top: 10px;
                    width: 150px;
                    height: 39px;
                    background: #ed4c67;
                    border-radius: 28px;
                    border: none;
                    color: #ffffff;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 19px;
                    text-transform: uppercase;
                }
                &.footerDrop {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 10px;
                    .customSvg {
                        margin-top: 5px;
                        margin-left: 10px;
                        transform: rotate(270deg);
                        path {
                            fill: #ffffff;
                        }
                    }
                    &:hover {
                        .customSvg {
                            color: #ed4c67;
                            path {
                                fill: #ed4c67;
                            }
                        }
                    }
                }
            }
        }
    }
}
$breakpoint-tablet: 1034px;

@media (max-width: $breakpoint-tablet) {
    .footer {
        div {
            width: 95%;
        }
        .footerSection {
            width: 95%;

            p {
                width: 95%;
            }
        }
    }
}
$breakpoint-mobile: 750px;
@media (max-width: $breakpoint-mobile) {
    .footer {
        min-height: max-content;
        height: auto;
        max-height: fit-content;

        div {
            width: 95%;
            flex-wrap: wrap;
            padding-bottom: 50px;
        }
    }
}
