.section_activites {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px 10px;
    width: 1100px;
    align-items: center;
    justify-items: center;
    .cardActivite {
        cursor: pointer;
        &:hover {
            transform: scale(1.02);
        }
        max-width: 330px;
        min-width: 330px;
        max-height: 210px;
        min-height: 210px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        text-align: center;
        color: #2c2d41;
        &.ter {
            background-color: #bed9bc;
        }
        &.mus {
            background-color: #fcdfdf;
        }
        &.sor {
            background-color: #eed3ff;
        }
        &.plo {
            background-color: #c4ebfc;
        }
        &.act {
            background-color: #f0dffc;
        }
        &.tec {
            background-color: #fcf7df;
        }
        #actualiteName {
            padding: 25px 0 10px 0;
            margin: 0;
            font-size: 20px;
            font-weight: 600;
            line-height: 25px;
            text-transform: uppercase;
        }
    }
}
.cardActivite div {
    padding: 0 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
}
$breakpoint-tablet: 1034px;

@media (max-width: $breakpoint-tablet) {
    .section_activites {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px 10px;
        width: 1100px;
        align-items: center;
        justify-items: center;
    }
}
$breakpoint-mobile: 750px;

@media (max-width: $breakpoint-mobile) {
    .section_activites {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-items: center;
    }
}
