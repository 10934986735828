@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css);

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #dff3fd;
  background-image: url("../src/assets/img/bg.svg");
  background-size: contain;
  background-position: 50% 30px;
  color: #2c2d41;
  width: 100%;
}

code {
  font-family: "Inter", sans-serif;
}
.main-content {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}
h2 {
  font-style: normal;
  font-size: 28px;
  font-weight: 600;
  line-height: 44px;
  text-transform: uppercase;
}

.slick-slider {
  width: 98%;
  margin-left: 15px;
  margin-right: auto;

  .slick-arrow.slick-next,
  .slick-arrow.slick-prev {
    width: 40px;
    height: 40px;

    &::before {
      font-size: 45px;
      padding: 20px;
      color: rgba(65, 165, 146, 0.61);
    }
  }

  .slick-arrow.slick-prev {
    left: -50px;
  }

  .slick-arrow.slick-next {
    right: -5px;
  }
}

$breakpoint-mobile: 750px;
@media (max-width: $breakpoint-mobile) {
  .slick-slider {
    width: 98%;
    margin-left: 0px;
    margin-right: auto;

    .slick-arrow.slick-next,
    .slick-arrow.slick-prev {
      width: 40px;
      height: 40px;

      &::before {
        font-size: 45px;
        padding: 20px;
        color: rgba(65, 165, 146, 0.61);
      }
    }

    .slick-arrow.slick-prev {
      left: -50px;
    }

    .slick-arrow.slick-next {
      right: -5px;
    }
  }
  .logo {
    display: none !important;
  }
}
.dropdownLink {
  cursor: pointer;
  text-decoration: none;

  .dropdownBody {
    position: absolute;
    left: -25px;
    background: rgba(65, 165, 146, 0.61);
  }
  :focus {
    outline: none;
  }
}

.hide-menu {
  display: none !important;
}
