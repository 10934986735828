.contactUsSection {
    display: flex;
    flex-direction: column;
    .contact {
        margin-left: auto;
        margin-right: auto;
        display: flex;
        padding: 0px;
        gap: 30px;
        input {
            width: 440px;
            height: 50px;
            background: rgba(255, 255, 255, 0.5);
            border: 1px solid #41a592;
            border-radius: 30px;
            margin: 10px 10px 20px 0;
            padding: 0 20px;
            font-size: 18px;
        }
        textarea {
            margin: 15px 0;
            padding: 20px 20px;
            width: 440px;
            max-width: 440px;
            min-width: 440px;
            height: 243px;
            min-height: 243px;
            background: rgba(255, 255, 255, 0.5);
            border: 1px solid #41a592;
            border-radius: 30px;
            font-size: 18px;
        }
        textarea:focus,
        input:focus {
            outline: none;
        }

        label {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
        }
    }
    .submitButton {
        margin-left: auto !important;
        margin-right: auto !important;
        background: rgba(65, 165, 146, 0.93);
        border: 1px solid #41a592;
        border-radius: 30px;
        width: 480px;
        height: 50px;
        margin: 15px 0;
        color: #ffffff;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        cursor: pointer;
    }
}
$breakpoint-tablet: 1000px;

@media (max-width: $breakpoint-tablet) {
    .contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        div {
            max-width: 500px;
            min-width: 500px;
        }

        input {
            max-width: 440px;
            min-width: 440px;
        }

        textarea {
            max-width: 440px;
            min-width: 440px;
        }
    }
}
$breakpoint-mobile: 750px;
@media (max-width: $breakpoint-mobile) {
    .contact {
        display: flex;
        flex-wrap: wrap;
        margin-left: auto;
        margin-right: auto;
        div {
            min-width: 95%;
            max-width: 95%;
        }

        input {
            margin-left: auto;
            margin-right: auto;
            max-width: 300px !important;
            min-width: 300px !important;
        }

        textarea {
            margin-left: auto;
            margin-right: auto;
            max-width: 300px !important;
            min-width: 300px !important;
        }
    }
    .submitButton {
        max-width: 340px;
        min-width: 340px;
    }
}
