.projetTermineTitle {
    margin: 40px 0;
}
.projetSection {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .cardProjetTermine {
        margin: 5px 20px 10px 5px;
        height: 420px;
        border-radius: 15px;
        background-color: white;
        cursor: pointer;
        img {
            max-width: 330px;
            min-width: 330px;
            height: 290px;
            object-fit: cover;
            border-radius: 15px;
        }
        .cardProjetTermineBody {
            max-width: 310px;
            min-width: 310px;
            margin-left: auto;
            margin-right: auto;
            h3 {
                font-size: 16px;
            }
        }
    }
}

$breakpoint-tablet: 1034px;

@media (max-width: $breakpoint-tablet) {
    .projetSection {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding-left: 0;
        .cardProjetTermine {
            cursor: pointer;
            max-width: 300px;
            min-width: 300px;
            img {
                max-width: 300px;
                min-width: 300px;
                height: 350px;
                object-fit: cover;
                border-radius: 15px;
            }
        }
    }
}
