.activitePage {
    .cardActivite {
        display: flex;
        margin-bottom: 40px;
        padding-bottom: 20px;
        .text {
            font-size: 16px;
            font-weight: 500;
            line-height: 25px;
        }
        img {
            width: 300px;
            height: 250px;
            object-fit: cover;
            object-position: top center;
            border-radius: 10px;
            margin: 15px;
        }
    }
}
$breakpoint-mobile: 750px;
@media (max-width: $breakpoint-mobile) {
    .activitePage {
        .cardActivite {
            display: flex;
            flex-wrap: wrap;
        }
    }
}
