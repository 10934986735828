.teamPage {
    h2 {
        padding: 0;
        font-size: 28px;
        font-weight: 800;
        line-height: 22px;
    }

    .teams {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-items: center;
        gap: 0 30px;
        padding-bottom: 50px;
        div {
            padding-bottom: 20px;
        }
    }
}

$breakpoint-tablet: 1034px;

@media (max-width: $breakpoint-tablet) {
    .teamPage {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        .teams {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}
$breakpoint-mobile: 750px;
@media (max-width: $breakpoint-mobile) {
    .teamPage {
        h2 {
            padding: 0;
            font-size: 24px;
            font-weight: 800;
            line-height: 24px;
        }
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        .teams {
            grid-template-columns: 1fr 1fr;
        }
    }
}
