.projet {
    display: flex;
    align-items: center;
    max-width: 330px;
    min-width: 330px;
    height: 180px;
    margin: 5px 20px 10px 5px;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #41a592;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    cursor: pointer;
    &.page {
        max-width: 330px;
        min-width: 330px;
        height: 180px;
    }
    .projetTitle {
        padding: 0 25px;
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        text-transform: uppercase;
    }
    &:hover {
        transform: scale(1.02);
    }
}
$breakpoint-tablet: 1034px;

@media (max-width: $breakpoint-tablet) {
    .projet {
        width: 328px;
    }
}
$breakpoint-mobile: 750px;
@media (max-width: $breakpoint-mobile) {
    .projet {
        min-width: 330px;
        max-width: 350px;
        &.page {
            min-width: 330px;
            max-width: 350px;
        }
    }
}
