.cardActualite {
    cursor: pointer;
    max-width: 330px;
    min-width: 330px;
    height: 320px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    position: relative;
    margin: 5px 20px 15px 5px;
    &:hover {
        transform: scale(1.02);
    }
    img {
        width: 100%;
        height: 190px;
        object-fit: cover;
        object-position: top center;
        border-radius: 15px;
    }
    div {
        padding: 10px;
        height: 80px;
        p {
            width: 270px;
            font-weight: 700;
            font-size: 16px;
            line-height: 25px;
            text-transform: uppercase;
            margin: 0px 10px;
        }
    }
    span {
        position: absolute;
        right: 15px;
        bottom: 10px;
    }
}
.mySlider {
    height: 340px;
}
