.accueil {
    margin-left: auto;
    margin-right: auto;

    .accueilText {
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        margin-left: auto;
        margin-right: auto;
    }
    .header {
        display: flex;
        margin: 100px 0 30px 0;
        justify-content: space-between;
        align-items: center;

        h2 {
            margin: 0;
            height: 44px;
            padding: 0;
        }
        div > a {
            text-decoration: none;
            color: #2c2d41;
            svg {
                transform: rotate(180deg);
                margin-left: 10px;
            }
        }
    }
    .challengeActuel {
        padding-bottom: 55px;
        .header {
            height: 100px;
            height: fit-content;
        }
        .body,
        .otherImg {
            display: flex;
            img {
                object-fit: cover;
                object-position: top center;
                border-radius: 10px;
                margin: 15px 25px 0 0;
                width: 400px;
            }
            .challenge {
                position: relative;
                margin-top: 10px;
                top: 25px;
                text-align: center;
                width: fit-content;
                padding: 20px 50px;
                height: 39px;
                background: #ed4c67;
                border-radius: 28px;
                border: none;
                color: #ffffff;
                font-weight: 600;
                font-size: 12px;
                line-height: 19px;
                text-transform: uppercase;
                text-decoration: none;
            }
        }

        span {
            font-size: 16px;
            font-weight: 600;
            line-height: 25px;
            position: relative;
            float: right;
        }
    }
}

$breakpoint-tablet: 1034px;

@media (max-width: $breakpoint-tablet) {
    .accueil {
        width: 100%;

        .accueilText {
            width: 80%;
            padding: 0 10px;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            margin-left: auto;
            margin-right: auto;
        }
        .challengeActuel {
            padding-bottom: 55px;
            width: 95%;
            margin-left: auto;
            margin-right: auto;
            .header {
                height: 100px;
                height: fit-content;
            }
            .body {
                display: flex;
                img {
                    object-fit: cover;
                    object-position: top center;
                    border-radius: 10px;
                    margin: 15px 25px 0 0;
                    max-width: 300px;
                }
                .challenge {
                    position: relative;
                    margin-top: 10px;
                    top: 25px;
                    text-align: center;
                    width: fit-content;
                    padding: 20px 50px;
                    height: 39px;
                    background: #ed4c67;
                    border-radius: 28px;
                    border: none;
                    color: #ffffff;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 19px;
                    text-transform: uppercase;
                    text-decoration: none;
                }

                span {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 25px;
                    position: relative;
                    float: right;
                }
            }
        }

        .section_actualite,
        .section_projet,
        .section_merci,
        .section_activites,
        .section_contact {
            width: 90%;
            padding: 5px 20px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
$breakpoint-mobile: 750px;

@media (max-width: $breakpoint-mobile) {
    .accueil {
        .challengeActuel {
            .header {
                height: fit-content;
                h2 {
                    height: fit-content;
                }
            }
            .body {
                display: flex;
                flex-wrap: wrap;
                img {
                    max-width: 350px;
                }
            }
        }
        .section_actualite,
        .section_projet,
        .section_merci,
        .section_contact {
            padding: 0;
            width: 95%;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
