main-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    padding-right: 26px;
    padding-left: 21px;
    margin-bottom: 100px;
    position: fixed;
    left: 0;
    z-index: 10;
    width: 100%;
}
.main-header {
    margin-bottom: 100px;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    div {
        position: relative;
        .banner-slider-wrapper {
            left: 0;
            width: 100%;
            background: rgba(65, 165, 146, 0.61);
            .slick-slider {
                width: 100%;
                margin: 0;
                position: relative;
                left: 0;
            }
            img {
                opacity: 0.52;
                width: 100%;
                object-fit: cover;
            }
            .slick-dots {
                bottom: 20%;
                li {
                    button::after {
                    }
                    button::before {
                        font-size: 16px;
                        color: #4eac99;
                    }
                }
            }
        }
        .header_wave {
            position: absolute;
            width: 100%;
            left: 0px;
            bottom: -10px;
        }
    }
    .title {
        position: absolute;
        width: 100%;
        top: 35%;
        h1 {
            margin: 0 auto;
            font-size: 34px;
            font-weight: 600;
            line-height: 48px;
        }
        h3 {
            font-size: 40px;
            font-weight: 600;
            line-height: 28px;
        }
        p {
            margin: 0 auto;
            font-size: 24px;
            font-weight: 600;
            line-height: 24px;
        }
    }

    .stickynavbar {
        position: fixed;
        top: 0;
        z-index: 3;
        width: 100%;

        .navbar {
            .desktopNav {
                .desktopLine {
                    width: 100%;
                    height: 20px;
                    background: rgba(65, 165, 146);
                }
                .desktopBar {
                    width: 100%;
                    padding: 0 10px 0 1 0px;
                    display: flex;
                    position: absolute;
                    justify-content: flex-end;
                    background: rgba(65, 165, 146, 0.61);

                    h3 {
                        font-family: "Inter";
                        font-style: normal;
                        font-weight: 800;
                        font-size: 16px;
                        line-height: 30px;
                        letter-spacing: 0.015em;
                        text-transform: uppercase;
                        color: #ffffff;
                        letter-spacing: 0.6px;
                        width: 260px;
                    }
                    nav {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        .nav {
                            padding-top: 0;
                            display: flex;
                            justify-content: flex-end;
                            a,
                            li {
                                list-style: none;
                                margin: 15px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center !important;
                                align-items: center;
                                text-decoration: none;
                                color: #ffffff;
                                text-transform: uppercase;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 22px;
                                letter-spacing: 0.6px;

                                &:hover {
                                    color: #ed4c67;
                                }
                                button {
                                    width: 150px;
                                    height: 39px;
                                    background: #ed4c67;
                                    border-radius: 28px;
                                    border: none;
                                    color: #ffffff;
                                    font-weight: 600;
                                    font-size: 12px;
                                    line-height: 19px;
                                    text-transform: uppercase;
                                    &:hover {
                                        color: #ed4c67;
                                        background: #ffffff;
                                    }
                                }
                                :focus {
                                    outline: none;
                                }
                            }
                            li {
                                display: flex;
                                flex-direction: row;
                                .customSvg {
                                    margin-left: 10px;
                                    transform: rotate(270deg);
                                    path {
                                        fill: #ffffff;
                                    }
                                }
                                &:hover {
                                    .customSvg {
                                        color: #ed4c67;
                                        path {
                                            fill: #ed4c67;
                                        }
                                    }
                                }
                            }
                        }
                        .logo {
                            margin-left: 20px;
                            width: 70px;
                        }
                    }
                    .active {
                        color: #ed4c67;
                        .customSvg {
                            color: #ed4c67;
                            path {
                                fill: #ed4c67;
                            }
                        }
                    }
                }
            }
            .mobileNavButton {
                display: none;
            }
            .mobileNav {
                display: none;
            }
            .hide-menu {
                display: none;
            }
        }
    }
}

$breakpoint-tablet: 1034px;
@media (max-width: $breakpoint-tablet) {
    .main-header {
        div {
            position: relative;
            .banner-slider-wrapper {
                left: 0;
                width: 100%;
                background: rgba(65, 165, 146, 0.61);
                .slick-slider {
                    width: 100%;
                    margin: 0;
                    position: relative;
                    left: 0;
                }
                img {
                    opacity: 0.52;
                    width: 100%;
                    object-fit: cover;
                }
                .slick-dots {
                    bottom: 20%;
                    li {
                        button::after {
                        }
                        button::before {
                            font-size: 16px;
                            color: #4eac99;
                        }
                    }
                }
            }
            .header_wave {
                // display: none;
                position: absolute;
                object-fit: scale-down;
                object-position: center;
                width: 100%;
                left: 0px;
                bottom: -10px;
            }
        }

        .title {
            position: absolute;
            width: 100%;
            top: 35%;
            margin: 0 auto;
            h1 {
                margin: 0 auto;
                font-size: 26px;
                font-weight: 600;
                line-height: 48px;
            }
            h3 {
                margin: 0 auto;
                font-size: 40px;
                font-weight: 600;
                line-height: 28px;
            }
            p {
                font-size: 20px;

                font-weight: 600;
                line-height: 24px;
            }
        }
        .stickynavbar {
            position: fixed;
            top: 0;
            z-index: 3;
            width: 100%;
            .navbar {
                .desktopNav {
                    display: none;
                }
                .mobileNavButton {
                    display: inline;
                    width: 50%;
                    position: absolute;
                    right: 0;
                    border-radius: 0px 0 0 10px;
                    .header-menu {
                        text-align: end;
                        padding: 10px 20px;
                        &.bground {
                            background-color: #dff3fd;
                        }
                    }

                    .mobileNav {
                        display: block;
                        border-radius: 0px 0 0 10px;
                        background-color: #dff3fd;
                        .nav {
                            padding-top: 0;
                            display: flex;
                            flex-direction: column;
                            padding: 5px 0 10px 0;

                            a {
                                list-style: none;
                                text-align: left;
                                padding: 15px 35px;
                                text-decoration: none;
                                color: #ed4c67;
                                text-transform: uppercase;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 22px;
                                letter-spacing: 0.6px;

                                &:hover {
                                    color: #ed4c67;
                                    background: #ffffff;
                                }
                                button {
                                    width: 150px;
                                    height: 39px;
                                    background: #ed4c67;
                                    border-radius: 28px;
                                    border: none;
                                    color: #ffffff;
                                    font-weight: 600;
                                    font-size: 12px;
                                    line-height: 19px;
                                    text-transform: uppercase;
                                    &:hover {
                                        color: #ed4c67;
                                        background: #ffffff;
                                    }
                                }
                            }
                        }
                        .active {
                            color: #ed4c67;
                            background: #ffffff;
                        }
                    }
                    .hide-menu {
                        display: none;
                    }
                    .show-menu {
                        display: block;
                    }
                }
            }
        }
    }
}
$breakpoint-mobile: 750px;
@media (max-width: $breakpoint-mobile) {
    .main-header {
        div {
            .banner-slider-wrapper {
                height: 500px;
                img {
                    height: 500px;
                    object-position: center center;
                }
                .slick-dots {
                    bottom: 10%;
                }
            }
            .header_wave {
                display: none;
            }
        }
        h1 {
            width: 90%;
            font-size: 22px;
            line-height: 34px;
        }
        p {
            width: 90%;
            font-size: 16px;
        }
        .stickynavbar {
            .navbar {
                .mobileNavButton {
                    width: 80%;
                }
            }
        }
    }
}
